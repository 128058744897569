import React from "react";
import {Route, Switch} from "react-router-dom";
import AuthenticatedRoute from "../components/AuthenticatedRoute";
import UnauthenticatedRoute from "../components/UnauthenticatedRoute";
import Loadable from 'react-loadable';
import Loading from '../components/loading/LoadingComponent';
/*
* Asynchroniczne pobieranie komponentow
* dzieki temu tworzonych jest kilka js zamiast jeden
* akraca czas potrzebny do odpalenia poszczegolnych podstron
* dodatkowo w trakcie ladowania wyswietla obiekt Loading
* w przypadku bledu ladowania obiekt loading zwroci odpowiedni tekst
* mozna zamienic to na jakas animacje
*/

const AsyncHome = Loadable({
    loader: () => import("../views/Home"),
    loading: Loading
});
const AsyncLogin = Loadable({
    loader: () => import("../views/Login"),
    loading: Loading
});
const AsyncNotFound = Loadable({
    loader: () => import("../views/NotFound"),
    loading: Loading
});
const AsyncFarmView = Loadable({
    loader: () => import("../views/new-farm-view/FarmView"),
    loading: Loading
});

const AsyncDevelop = Loadable({
    loader: () => import("../views/Develop"),
    loading: Loading
});
const AsyncReport = Loadable({
    loader: () => import("../views/new-reports-view/ReportsView"),
    loading: Loading
});

const AsyncMaintenance = Loadable({
    loader: () => import("../views/maintenance-view/MaintenanceView"),
    loading: Loading
});
const AsyncTest = Loadable({
    loader: () => import("../views/TestView"),
    loading: Loading
});
const AsyncDebugDispenser = Loadable({
    loader: () => import("../views/debug-dispenser-view/DebugDispenserView"),
    loading: Loading
});

const AsyncNotificationCenter = Loadable({
    loader: () => import("../views/new-notifications-view/NotificationsView"),
    loading: Loading
});
const AsyncFarmChooser = Loadable({
    loader: () => import("../views/farm-chooser-view/FarmChooserView"),
    loading: Loading
});

const AsyncEventChooser = Loadable({
    loader: () => import("../views/event-chooser-view/EventChooserView"),
    loading: Loading
});

//SETTINGS - USTAWIENIA
const AsyncSettingsView = Loadable({
    loader: () => import("../views/new-settings-view/SettingsView"),
    loading: Loading
});

const AsyncAdminSubscribeView = Loadable({
    loader: () => import("../views/new-settings-view/settings/license/subscriptions/subscribe/AdminSubProcess"),
    loading: Loading
});

const AsyncEULA = Loadable({
    loader: () => import("../views/eula-view/EulaView"),
    loading: Loading
});

const AsyncPricing = Loadable({
    loader: () => import("../views/pricing-view/PricingView"),
    loading: Loading
});

const AsyncAcceptDocumentView = Loadable({
    loader: () => import("../views/accept-document-view/AcceptDocumentView"),
    loading: Loading
});

const AsyncChangeLog = Loadable({
    loader: () => import("../views/change-log-view/ChangeLogView"),
    loading: Loading
});

const AsyncSignUp = Loadable({
    loader: () => import("../views/sign-up/SignUpView"),
    loading: Loading
});

const AsyncLogs = Loadable({
    loader: () => import("../views/logs-view/LogsView"),
    loading: Loading
});

const AsyncAcceptCert = Loadable({
    loader: () => import("../views/accept-cert-view/AcceptCertView"),
    loading: Loading
});

const AsyncHelp = Loadable({
    loader: () => import("../views/help-view/HelpView"),
    loading: Loading
});

const AsyncScannedRFIDList = Loadable({
    loader: () => import("../views/last-scanned-rfid/LastScannedRFIDView"),
    loading: Loading
});


const AsyncConfirmUser = Loadable({
    loader: () => import("../views/confirm-user-view/ConfirmUserView"),
    loading: Loading
});

const AsyncControlLists = Loadable({
    loader: () => import("../views/control-lists-view/ControlListView"),
    loading: Loading
});

const AsyncSell = Loadable({
    loader: () => import("../views/sell-view/SellView"),
    loading: Loading
});

const AsyncMobileApp = Loadable({
    loader: () => import("../views/mobile-rfid-scanner/RFIDScannerMainView"),
    loading: Loading
});

const AsyncBilling = Loadable({
    loader: () => import("../views/farm-settings-view/FarmSettings"),
    loading: Loading
});

const AsyncAlarms = Loadable({
    loader: () => import("../views/alarms-view/AlarmsView"),
    loading: Loading
});
/*
* Metoda przekierowujaca
* przyjmuje jako parametr childProps - sa w niej zawrte informacje o zautoryzowanym uzytkowniku
* oraz lang - ktory przenosi tlumaczenia(statyczne teksty)
* */
export default ({childProps}) =>
    <Switch>
        <AuthenticatedRoute path="/" exact component={AsyncHome} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={AsyncLogin} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/reports" component={AsyncReport} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/eventChooser" component={AsyncEventChooser} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/settings" component={AsyncSettingsView} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/sell" component={AsyncSell} props={childProps}/>
        <AuthenticatedRoute path="/getstarted" component={AsyncAdminSubscribeView} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/farm" exact component={AsyncFarmView} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/farm/:viewType" exact component={AsyncFarmView} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/notificationCenter" exact component={AsyncNotificationCenter}
                            props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/controlLists/:active" exact component={AsyncControlLists}
                            props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/notificationCenter/:id" exact component={AsyncNotificationCenter}
                            props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/alarms" exact component={AsyncAlarms} props={childProps}/>
        <AuthenticatedRoute path="/farmChooser" exact component={AsyncFarmChooser} props={childProps}/>
        <AuthenticatedRoute path="/develop" exact component={AsyncDevelop} props={childProps}/>
        <AuthenticatedRoute path="/debugDispenser" exact component={AsyncDebugDispenser} props={childProps}/>
        <AuthenticatedRoute path="/accept/:documentId" exact component={AsyncAcceptDocumentView} props={childProps}/>
        <Route path="/changeLog" exact component={AsyncChangeLog} props={childProps}/>
        <AuthenticatedRoute path="/logs" exact component={AsyncLogs} props={childProps}/>
        <AuthenticatedRoute path="/help" exact component={AsyncHelp} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/lastScannedRFID" exact component={AsyncScannedRFIDList} props={childProps}/>
        <AuthenticatedRoute path="/:FarmID/mobile" component={AsyncMobileApp} props={childProps}/>
        <AuthenticatedRoute path="/farmSettings" component={AsyncBilling} props={childProps}/>
        <Route path="/maintenance" exact component={AsyncMaintenance} props={childProps}/>
        <Route path="/test" exact component={AsyncTest} props={childProps}/>
        <UnauthenticatedRoute path="/eula" exact component={AsyncEULA} props={childProps}/>
        <UnauthenticatedRoute path="/pricing" exact component={AsyncPricing} props={childProps}/>
        <UnauthenticatedRoute path="/signup" exact component={AsyncSignUp} props={childProps}/>
        <AuthenticatedRoute path="/confirm/:code/:username" exact component={AsyncConfirmUser} props={childProps}/>
        <Route path="/acceptCert" exact component={AsyncAcceptCert}/>
        <Route path="/reload" exact component={null}/>

        {/* Finally, catch all unmatched routes */}
        <Route component={AsyncNotFound}/>
    </Switch>;
